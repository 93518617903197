import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgClose from "./../../../images/ic_close.svg"
import { COLORS } from "../../../styles/colors"
import InviteNewUser from './InviteNewUser'
import InviteSentSuccess from './InviteSentSuccess'
import ChangePlan from './ChangePlan'
import { API, Auth } from "aws-amplify"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 705px;
  height: 476px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.Primary};
  border-radius: 20px;
  ${media.lessThan("743px")`
    /* justify-content: center; */
    width: auto;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    `}
  ${media.between("744px", "large")`
    padding-bottom: 30px;
    width: 650px;
  `}
  ${media.greaterThan("large")`
    padding-bottom: 30px;
    `}
`

const BtnClose = styled.button`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
  background: transparent;
  border: none;
  outline: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`

export default function InviteUserRouter(props) {

  const [inviteFlow, setInviteFlow] = useState()
  const [firstLoad, setFirstLoad] = useState(false)

    useEffect(() => {
      if(firstLoad == false){
        getCompanySeats()
        setFirstLoad(true)
      }
    }, [])

    const getCompanySeats = async (data) => {
  
        const token = (await Auth.currentSession()).getIdToken()
        const jwt = token.getJwtToken()
        const init = {
          headers: { Authorization: `Bearer ${jwt}` }
        }
  
        API.get('FrontendAPI-companies', '/details', init)
          .then((response) => {
            if (response.success) {
                if(response.data && response.data.config && response.data.config.seats){
                    if(props.seatsUsed >= response.data.config.seats){
                      setInviteFlow(2)
                    } else {
                      setInviteFlow(0)
                    }
                }
            }
          })
        .catch((err) => {
          console.error(err)
        })
      }

  const inviteSuccess = () => {
      setInviteFlow(1)
  }

  const changePlan = () => {
    setInviteFlow(2)
  }

  const backToInvite = () => {
    setInviteFlow(0)
  }


  return (
      <Container>
      <BtnClose onClick={props.closePopup}>
        <CloseIcon src={ImgClose} />
      </BtnClose>

      {/* -----------------------------Change User & Enter Email */}

      {(() => {
            switch (inviteFlow) {
                case 0:
                    return <InviteNewUser refreshTable={props.refreshTable} inviteSuccess={inviteSuccess} changePlan={changePlan}/>
                case 1:
                    return <InviteSentSuccess backToInvite={backToInvite} closePopup={props.closePopup}/>;
                case 2:
                    return <ChangePlan closePopup={props.closePopup}/>
                default:
                return null;
              }
        })()}
      
    </Container>

  )
}
