import React, { Link, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../../images/ic_arrow_right_green.svg"
import ImgClose from "./../../../images/ic_close.svg"
import ImgCheck from "./../../../images/ic_check_circle.svg"
import ImgAccount from "./../../../images/ic_account_white.svg"
import ImgVerified from "./../../../images/ic_verified.svg"
import ImgViewer from "./../../../images/ic_viewer.svg"
import ImgProperty from "./../../../images/ic_property.svg"
import { COLORS } from "../../../styles/colors"
import { BodySmall, FieldText, H1, H2 } from "../../../styles/fonts"
import { API, Auth } from "aws-amplify"


const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 705px;
  height: 466px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.Primary};
  border-radius: 20px;
  ${media.lessThan("743px")`
    justify-content: center;
    width: auto;
    height: 480px;
    `}
  ${media.between("744px", "large")`
    padding-bottom: 30px;
    width: 650px;
  `}
  ${media.greaterThan("large")`
  padding-bottom: 30px;
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 350px;
  justify-content: center;
  align-self: center;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnClose = styled.button`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
  background: transparent;
  border: none;
  outline: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`

const WingnutIcon = styled.img`
  /* width: 16px; */
  /* height: 16px; */
  /* margin-bottom: 17px; */
  align-self: center;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  align-self: center;
`

const Checkbox = styled.input`
  border: 1px solid ${COLORS.White};
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 20px;
  width: 20px;
  height: 20px;
  background: transparent;
`

const ButtonContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 43px;
  gap: 10px;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 237px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: #63c085;
  color: black;
  cursor: pointer;
  margin-bottom: 10px;
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-top: 28px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnNo = styled(Button)`
  background: transparent;
  color: ${COLORS.White};
  margin-left: 5px;
  ${media.lessThan("743px")`
        align-self: center;
    `}
`

const ButtonTextNo = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  transition-duration: 0.5s;
  :hover {
  }
`

const ButtonTextYes = styled(ButtonTextNo)`
  color: ${COLORS.Base};
`

const StackColumn = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;      
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-self: center;
    gap: 0;
  `;

const StackRow = styled.div`
    /* border: 1px red dashed; */
    display: flex;      
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0;
`;

export default function ChangeUserPopup(props) {
  const [isHoveringYes, setIsHoveringYes] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [checked, setChecked] = useState(false)

  return (

      <WingnutDetails style={{height: '100%'}}>
        <img src={ImgCheck} style={{width: '20px', alignSelf: 'center'}} />
        <H2 style={{alignSelf: 'center', color: COLORS.White, marginTop: '12px'}}>
            User type successfully changed
        </H2>
        
          <Button
            onMouseEnter={(e) => {
              setIsHoveringYes(true)
            }}
            onMouseLeave={(e) => {
              setIsHoveringYes(false)
            }}
            onClick={props.closePopup}
            style={{ opacity: isHoveringYes == true ? "80%" : "100%", alignSelf: 'center', marginTop: '50px', width: '237px' }}
          >
            <ButtonTextYes>CLOSE</ButtonTextYes>
          </Button>
      </WingnutDetails>

  )
}
