import React, { useState, useEffect, useContext } from "react"
import { API, Auth } from "aws-amplify"
import { Link, useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import MediaQuery from "react-responsive"
import Image from "./../../images/ic_help_green.svg"
import ImgArrowBack from "./../../images/ic_arrow_back.svg"
import Modal from "react-modal"
import PopupContainer from "./../popup/PopupContainer"
import { AccountContext } from "../../auth/Account"
import { COLORS } from "../../styles/colors"
import { cleanUUIDFromPath, getUUIDFromPath } from "../util/CleanUUID"
import InviteUserRouter from "../users/new_user_modal/InviteUserRouter"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}

export default function Header(props) {
  const history = useHistory()
  var location = useLocation()

  const [isHovering, setIsHovering] = useState(false)
  const [isHoveringBack, setIsHoveringBack] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  const { getSession } = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  const [state, setState] = useState({
    itemUUID: "",
    heading: "Dashboard",
    modal: false,
    date: "",
    showBack: true,
    showButton: true,
    buttonText: "SUBMIT",
    buttonIcon: false,
    buttonDestination: "",
    popupNumber: null,
    buttonTransparent: false
  })

  // So dev can manually pull token from UI
  const  getToken = async ()  => {
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()

    if(process.env.REACT_APP_API_DOMAIN !== "prod"){
      console.log("TOKEN", jwt)
    }
  }

  useEffect(() => {
    getSession().then(({ isAuth }) => {
      setIsAuth(isAuth)
    })
  }, [])

  useEffect(() => {
    var pathUUID = getUUIDFromPath(location.pathname);
    var pathname = cleanUUIDFromPath(location.pathname);
    console.log("run header switch", location.pathname)
    switch (pathname) {
      case "help":
        return setState({ heading: "Help", showBack: true, showButton: false, popupNumber: null })
      case `dashboard/${pathUUID}`:
        getCompany()
        return
      case "account":
        return setState({
          heading: "Account Settings",
          showBack: false,
          showButton: true,
          buttonText: "HELP",
          buttonIcon: true,
          buttonDestination: "/portal/help",
          popupNumber: null,
          buttonTransparent: true,
        })
      case "wingnut":
        return setState({
          heading: "Wingnuts Overview",
          showBack: false,
          showButton: isAuth ? true : false,
          buttonText: "ADD WINGNUT",
          buttonIcon: false,
          buttonDestination: "/portal/wingnut/add",
          popupNumber: null,
          buttonTransparent: false,
        })
      case "wingnut/detail":
        getWingnutId()
        return
      case "wingnut/add":
        return setState({ heading: "Add Wingnut", showBack: true, showButton: false, popupNumber: null })
      case "wingnut/link":
        return setState({ heading: "Link Wingnut", showBack: true, showButton: false, popupNumber: null })
      case "property":
        return setState({
          heading: "Properties Overview",
          showBack: false,
          showButton: isAuth ? true : false,
          buttonText: "ADD PROPERTY",
          buttonIcon: false,
          buttonDestination: "/portal/property/add",
          popupNumber: null,
          buttonTransparent: false,
        })
      case "property/add":
        return setState({ heading: "Add Property", showBack: true, showButton: false, popupNumber: null })
      case "property/link":
        return setState({ heading: "Link Wingnut", showBack: true, showButton: false, popupNumber: null })
      case "property/detail":
        getPropertyName()
        return
      case "notification":
        return setState({ heading: "Notifications", showBack: false, showButton: false, popupNumber: null })
      case "notification/edit":
        return setState({ heading: "Notifications", showBack: true, showButton: false, popupNumber: null })
      case "users":
        return setState({
          heading: "Users",
          showBack: true,
          showButton: false,
          popupNumber: null
        })
    }
  }, [location])



  const getCompany = async (pathURL) => {
    // time should be in the URL as 1683255098000
    const { company } = await getSession()

    var path = ""
    var date = ""

    if(pathURL !== undefined){
       path = pathURL.substring(8).split("/")
    } else {
       path = location.pathname.substring(8).split("/")
    }

    const selection = path[path.length - 1]

    if(selection.length > 11){
      date = getDashboardDate(selection)
    } else {
      date = getDashboardDate()
    }

    setState({
      heading: `${company}`,
      date: `${date}`,
      showBack: false,
      showButton: true,
      buttonText: "HELP",
      buttonIcon: true,
      buttonDestination: "/portal/help",
      popupNumber: null,
      buttonTransparent: true,
    })
  }

  const getDashboardDate =  (time) => {
    var date = null
    var formattedDate = ''

    if(time !== undefined && !isNaN(time)){
      date = new Date(Number(time));

      const monthAndYear = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }).toUpperCase();
       formattedDate = `${monthAndYear}`;  // Output: "MARCH 2023"

    } else {
      date = new Date();   
      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
      const monthAndDay = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }).toUpperCase();
      
      // Get the day suffix (e.g. "th")
      const daySuffix = (() => {
        const dayOfMonth = date.getDate();
        if (dayOfMonth >= 11 && dayOfMonth <= 13) {
          return 'th';
        }
        switch (dayOfMonth % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
      })();
      
     formattedDate = `${dayOfWeek}, ${monthAndDay}${daySuffix}`;   // Output: "THURSDAY, MARCH 24th"
    }
 
    return formattedDate
  }

  const getPropertyName = async () => {
    setState({
      itemUUID: "",
      heading: "",
      date: "",
      showBack: false,
      showButton: false,
      buttonText: "",
      buttonIcon: false,
      buttonDestination: false,
      popupNumber: null,
      buttonTransparent: false,
    })

    var path = location.pathname.substring(8).split("/")
    const uuid = path[path.length - 1]
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }
    
    const response = await API.get('FrontendAPI-properties', `/${uuid}`, init)
    if (response.success) {

      setState({
        itemUUID: uuid,
        heading: response.data.config.name,
        date: "",
        showBack: false,
        showButton: isAuth ? true : false,
        buttonText: "PROPERTY DASHBOARD",
        buttonIcon: false,
        buttonDestination: `/portal/dashboard/${response.data.id}`,
        popupNumber: null,
        buttonTransparent: false
      })
    }
  }

  const getWingnutId = async () => {
    setState({
      itemUUID: "",
      heading: "",
      date: "",
      showBack: false,
      showButton: false,
      buttonText: "",
      buttonIcon: false,
      buttonDestination: false,
      popupNumber: null,
      buttonTransparent: false,
    })

    var path = location.pathname.substring(8).split("/")
    const uuid = path[path.length - 1]
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }
    
    const response = await API.get('FrontendAPI-wingnuts', `/${uuid}`, init)
    if (response.success) {
      setState({
        itemUUID: uuid,
        heading: `Wingnut ${response.data.externalId}`,
        date: "",
        showBack: true,
        showButton: isAuth ? true : false,
        buttonText: "REMOVE WINGNUT",
        buttonIcon: false,
        buttonDestination: `/portal/wingnut/detail/${uuid}`,
        popupNumber: 0,
        buttonTransparent: true,
      })
    }
      
  }

  const closePopup = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <Modal isOpen={isOpen} onRequestClose={closePopup} style={customStyles} contentLabel='Wingnut Login'>
        <PopupContainer uuid={state.itemUUID} closePopup={closePopup} popupNumber={state.popupNumber} />
      </Modal>

      <Container>
        <HeaderContainer>
          <TitleContainer>
            {state.showBack ? 
              <BtnBack
                onMouseEnter={(e) => {
                  setIsHoveringBack(true)
                }}
                onMouseLeave={(e) => {
                  setIsHoveringBack(false)
                }}
                onClick={history.goBack} >
                <ArrowIconRight 
                  src={ImgArrowBack}
                  style={{opacity: isHoveringBack == true ? 1 : 0, display: isHoveringBack == true ? "inline" : "none", }} />
                <BackText            
                  style={{marginLeft: isHoveringBack == true ? "5px" : "0px"}}>
                    Back
                </BackText> 
              </BtnBack>
            : 
            null}

            <HeaderContainer2>
              <HeaderText>{state.heading}</HeaderText>
              {
                state.date && state.date.length !== 0 
                ? <HeaderLine/>
                : null
              }
             
              <HeaderDate>{state.date}</HeaderDate>
            </HeaderContainer2>

            <ModeText onClick={getToken} style={{display: process.env.REACT_APP_API_DOMAIN !== "prod" ? "block" : "none"}}>{process.env.REACT_APP_API_NAME}</ModeText>
          </TitleContainer>

          <MediaQuery minWidth={744}>
            {state.showButton ? (
              <Link to={state.buttonDestination} style={{ textDecoration: "none", alignSelf: "center" }}>
                {state.buttonTransparent ? 
                    <ButtonTransparent 
                      onMouseEnter={(e) => {
                        setIsHovering(true)
                      }}
                      onMouseLeave={(e) => {
                        setIsHovering(false)
                      }}
                      onClick={(e) => {
                        setIsHovering(false)
                        if (state.popupNumber != null) {
                          setIsOpen(true)
                        }
                      }}
                    >
                      {state.buttonIcon ? <Icon src={Image} /> : null}
                        <ButtonTransparentText>
                          {state.buttonText}
                        </ButtonTransparentText>
                    </ButtonTransparent>
                    :
                    <Button 
                      onMouseEnter={(e) => {
                        setIsHovering(true)
                      }}
                      onMouseLeave={(e) => {
                        setIsHovering(false)
                      }}
                      onClick={(e) => {
                        setIsHovering(false)
                        if (state.popupNumber != null) {
                          setIsOpen(true)
                        } else if(state.modal){
                          setIsInviteModalOpen(true)
                        }
                      }}>
                      {state.buttonIcon ? <Icon src={Image} /> : null}
                      <ButtonText>
                        {state.buttonText}
                      </ButtonText>
                    </Button>}
              </Link>
            ) : null}
          </MediaQuery>

          <MediaQuery maxWidth={743}>
              {state.showButton ? (
                  <Link to={state.buttonDestination} style={{ textDecoration: "none", alignSelf: "center" }}>
                    {state.buttonTransparent ? 
                        <ButtonTransparent 
                          onMouseEnter={(e) => {
                            setIsHovering(true)
                          }}
                          onMouseLeave={(e) => {
                            setIsHovering(false)
                          }}
                          onClick={(e) => {
                            setIsHovering(false)
                            if (state.popupNumber != null) {
                              setIsOpen(true)
                            }
                          }}
                        >
                          {state.buttonIcon ? <Icon src={Image} /> : null}

                          <ButtonTransparentText
                          >
                            {state.buttonText}
                          </ButtonTransparentText>
                        </ButtonTransparent>
                        :
                        <Button 
                          onMouseEnter={(e) => {
                            setIsHovering(true)
                          }}
                          onMouseLeave={(e) => {
                            setIsHovering(false)
                          }}
                          onClick={(e) => {
                            setIsHovering(false)
                            if (state.popupNumber != null) {
                              setIsOpen(true)
                            } else if(state.modal){
                              setIsInviteModalOpen(true)
                            }
                          }}
                        >
                          {state.buttonIcon ? <Icon src={Image} /> : null}
                          <ButtonText>
                            {state.buttonText}
                          </ButtonText>
                        </Button>
                        }
                  </Link>
                ) : null}
          </MediaQuery>

        </HeaderContainer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  /* border: 1px red dashed; */
  background-color: ${COLORS.Base};
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-bottom: 20px; */
  ${media.lessThan("743px")`
        align-content: center;
        height: 100%;
    `}
`

const HeaderContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  /* margin-bottom: 20px; */
  ${media.lessThan("743px")`
        flex-direction: column;
        align-content: center;
        justify-content: center;
    `}
`

const HeaderContainer2 = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  /* margin-bottom: 20px; */
  ${media.lessThan("743px")`
        /* flex-direction: column; */
        /* align-content: center; */
        justify-content: center;
    `}
`

const TitleContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  ${media.lessThan("743px")`
        align-content: center;
        width: 100%;
        /* align-self: center; */
    `}
`

const HeaderText = styled.h2`
font-family: 'Blinker';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 84.5%;
display: flex;
align-items: center;
letter-spacing: -0.01em;
color: #FFFFFF;
  ${media.lessThan("743px")`
        /* align-self: center; */
        /* max-width: 95%; */
        /* text-wrap: wrap; */
        /* text-align: center; */
        /* margin-top: 10px; */
    `}
`

const HeaderLine = styled.h2`
  border: 1px solid #4C628C;
  height: 28px;
  margin-left: 14px;
  margin-right: 14px;
/* transform: rotate(90deg); */
`

const HeaderDate = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
`

const ModeText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.ErrorOrange};
  padding-top: 10px;
  padding-bottom: 10px;
  ${media.lessThan("743px")`
        align-self: center;
        max-width: 95%;
        text-wrap: wrap;
        text-align: center;

    `}
`

const BackText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  color: ${COLORS.HighlightMedium};
  margin-bottom: 10px;
  cursor: pointer;
  transform: translateZ(1px);
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        align-self: center;
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* width: 175px; */
  padding-left: 40px;
  padding-right: 40px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: ${COLORS.Green};
  color: ${COLORS.White};
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.05em;
  margin-right: 30px;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        height: 55px;
        margin-right: 0px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonTransparent = styled(Button)`
  background: transparent;
  :hover {
    opacity: 1;
    background: ${COLORS.Secondary};
  }
`;

const Icon = styled.img`
  /* border: 1px red dashed; */
  width: 15px;
  height: 15px;
  margin-right: 9px;
  margin-bottom: 2.5px;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BtnBack = styled.div`
  /* border: 1px red dashed; */
  height: 25px;
  display: flex;
  flex-direction: row;
  align-content: center;
  cursor: pointer;
  margin-bottom: 5px;
  ${media.lessThan("743px")`
    margin-top: 20px;
    align-self: center;
  `}
`;

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  color: black;
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
  /* margin-top: 3px; */
  /* line-height: 130%; */
`;

const ButtonTransparentText = styled(ButtonText)`
  color: ${COLORS.White};
`;

const ArrowIconRight = styled.img`
  width: auto;
  height: 16px;
  margin-right: 5px;  
  ${media.lessThan("743px")`
  `}
  ${media.between("744px", "large")`
  align-self: center;
  `}
  ${media.greaterThan("large")`
  align-self: center;
  `}
`