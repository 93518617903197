import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "./../../auth/Account"
import media from "styled-media-query"
import styled from "styled-components"
import UsersTable from "./UsersTable"
import IconUser from "./../../images/ic_account.svg"
import IconVerified from "./../../images/ic_verified.svg"
import IconPencil from "./../../images/ic_pencil_blue.svg"
import IconAlertBlue from "./../../images/ic_alert_blue_light.svg"
import IconSort from "./../../images/ic_arrows_sort.svg"
import ScreenSize from "./../wingnuts/ScreenSize.enum"
import MediaQuery from "react-responsive"
import { COLORS } from "./../../styles/colors"
import ChangeUserRouter from "./edit_user_modal/ChangeUserRouter"
// import DeleteUserPopup from "./edit_user_modal/ChangeUserPopup"
import Modal from "react-modal"

// Modal style
const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: COLORS.Primary,
      borderRadius: "30px",
      border: "none",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: 10,
    },
  }

// Handle the highlevel table construction
function UsersTableStructure(props) {

  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [loggedInUserEmail, setLoggedInUserEmail] = useState()

  const openChangePopup = (e, details) => {
      setUserDetails({
        userId: details.uuid,
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        phone: details.phone,
        type: details.type,
        status: details.status
      })
      setIsChangeModalOpen(true)
  }
  
  const openDeletePopup = (e, details) => {
    setUserDetails({
      userId: details.uuid,
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      phone: details.phone,
      type: details.type,
      status: details.status
    })
    setIsDeleteModalOpen(true)
  }

  const closeChangePopup = () => {
    setIsChangeModalOpen(false)
  }
  
  const closeDeletePopup = () => {
    setIsDeleteModalOpen(false)
  }

  //----------------------------------------Custom Sort Functions
  const basicSort = React.useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const a = rowA.original[columnId]
        const b = rowB.original[columnId]
        if(a > b) return 1
        if (b > a) return -1
        return 0
    }
}, [])

  const tableColumns = React.useMemo(
    () => [
      {
        // Turned int function to get access to column props { props.column.isSorted ? props.column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
        Header: (props) => {
          return (
            <HeaderDiv>
              <HeaderText>First Name</HeaderText>
              <BtnIcon>
                <SortIcon src={IconSort} />
              </BtnIcon>
            </HeaderDiv>
          )
        },
        accessor: "firstName",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '8%',
        Cell: (props) => {
            // console.log("props firstName", props.firstName)
          return (
              <CellDiv>
                  <Text style={{paddingLeft: '5px', opacity: props.isLoggedIn ? 0.5 : 1.0}}>{props.firstName}</Text>
              </CellDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <HeaderText>Last Name</HeaderText>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "lastName",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '8%',
        Cell: (props) => {
          return (
            <CellDiv>
                <Text style={{paddingLeft: '5px', opacity: props.isLoggedIn ? 0.5 : 1.0}}>{props.lastName}</Text>
            </CellDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <HeaderText>Phone Number</HeaderText>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "phone",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '8%',
        Cell: (props) => {
          return (
            <CellDiv>
              <StaticText style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>{props.phone}</StaticText>
            </CellDiv>
            )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <HeaderText>Email</HeaderText>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "email",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '10%',
        Cell: (props) => {
          return (
            <CellDiv>
              <StaticText style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>{props.email}</StaticText>  
            </CellDiv>
            )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <HeaderText>Type</HeaderText>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "type",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '7%',
        Cell: (props) => {
            // console.log("type", props.type)
          return (
              <CellDiv>
                {/* ANNA CSS THIS */}
                <ContentDiv style={{marginTop: '5px'}}>

                        {
                          props.status === 'pending' ? 
                            <TypeIcon style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>
                              <UserIcon src={IconUser} style={{marginRight: '10px'}} />  
                              <Text style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>Invited</Text>  
                            </TypeIcon> 

                          :

                          (() => {
                                switch (props.type) {
                                    case "admin":
                                        return (
                                          <TypeIcon style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>
                                            <AdminIcon src={IconVerified} style={{marginRight: '8px'}}/>
                                            <Text style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>Admin</Text>
                                          </TypeIcon>
                                        )
                                    case "write":
                                        return (
                                          <TypeIcon style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>
                                            <UserIcon src={IconUser} style={{marginRight: '10px'}} />  
                                            <Text style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>Editor</Text>  
                                          </TypeIcon> 
                                        )
                                    case "read":
                                      return (
                                          <TypeIcon style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>
                                            <UserIcon src={IconUser} style={{marginRight: '10px'}} />  
                                            <Text style={{opacity: props.isLoggedIn ? 0.5 : 1.0}}>Viewer</Text>  
                                          </TypeIcon>  
                                      )
                                    default:
                                    return null;
                                  }
                            })()                           
                        } 
                </ContentDiv>
              </CellDiv>
        )},
      },
      {
        Header: (
          <HeaderDiv>
            <HeaderText>Edit</HeaderText>
          </HeaderDiv>
        ),
        accessor: "edit",
        // sortType: basicSort,
        width: '4%',
        Cell: (props) => {
            // console.log("Props edit", props)
          return (
            <EditUser style={{opacity: props.isLoggedIn ? 0.5 : 1.0, cursor: props.isLoggedIn ? 'default' : 'pointer'}}>

              { props.status === 'pending' ? 
                    <AlertIcon onClick={(e) => openDeletePopup(e, props)} src={IconPencil} style={{marginRight: '15px'}} /> 
                  :
                    <AlertIcon src={IconPencil} style={{marginRight: '15px'}} onClick={(e) => {
                      if(props.isLoggedIn === false){
                        openChangePopup(e, props)
                      }
                    } }/>   
                  }

            </EditUser>
          )
        },
      },
    ],
    []
  )

  const hiddenColumns = (screenSize) => {
    // propertyId, propertyName, address, portfolio, linkedWingnuts, status
    var columnsToHide = []
    if (screenSize === ScreenSize.Medium) {
      // propertyId, propertyName, portfolio, linkedWingnuts, status
      columnsToHide = ["address", "portfolio"]
    }
    if (screenSize === ScreenSize.Small) {
      // propertyId, propertyName, status
      columnsToHide = ["address", "portfolio", "linkedWingnuts"]
    }
    return columnsToHide
  }

  return (
    <Container>
          <Modal isOpen={isChangeModalOpen} onRequestClose={closeChangePopup} style={customStyles}>
            <ChangeUserRouter refreshTable={props.refreshTable} popup={0} userDetails={userDetails} closePopup={closeChangePopup}/>
          </Modal>
          <Modal isOpen={isDeleteModalOpen} onRequestClose={closeDeletePopup} style={customStyles}>
            <ChangeUserRouter refreshTable={props.refreshTable} popup={2} userDetails={userDetails} closePopup={closeDeletePopup}/>
          </Modal>
      <Styles>
        <MediaQuery minWidth={1171}>
          <UsersTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Large)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery minWidth={744} maxWidth={1170}>
          <UsersTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Medium)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery maxWidth={743}>
          <UsersTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Small)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>
      </Styles>
    </Container>
  )
}

export default UsersTableStructure



const Container = styled.div`
  /* border: 1px solid red; */
  overflow: auto;
  height: 100%;
  width: 100%;
  margin-top: 0px;
  ${media.lessThan("743px")`
    align-self: center;
    margin-top: 0px;  
    padding-left: 16px;  
    padding-right: 16px;   
    `}
  ${media.between("744px", "large")`
      margin-top: 20px;
      width: 98%;
    `}
    ${media.greaterThan("large")`
    `}
`

const Styles = styled.div`
  /* padding-top: 10px; */
  /* border: 1px solid green; */
  color: ${COLORS.White};
  overflow: none;

  table {
    border-spacing: 0;
    /* border: 1px solid blue; */

    thead {
      ${"" /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;

      // Below create a fixed header - from here -> https://codesandbox.io/s/54z882qno4?file=/index.css:192-256
      position: sticky;
      top: 0;
      z-index: 2;
    }

    tbody {
      ${"" /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      :last-child {
        td {
          border-bottom: 1;
        }
      }
    }

    th,
    td {
      margin: 0;
      border-bottom: 1px solid ${COLORS.HighlightDark};

      :last-child {
        border-right: 0;
      }
    }
  }
`
const HeaderDiv = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  height: 45px;
`

const CellDiv = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  bottom: 1px;
  height: 45px;
`

const ContentDiv = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
`;

const BtnIcon = styled.button`
  /* border: 1px green dashed; */
  background: transparent;
  /* outline: none; */
  border: none;
`

const TypeIcon = styled.div`
  /* border: 1px green dashed; */
  /* border: none; */
  display: flex;
  background: transparent;
  /* outline: none; */
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const SortIcon = styled.img`
  /* border: 1px red dashed; */
  width: 18px;
  height: 18px;
  position: relative;
  /* align-self: center; */
  top: 1px;
  margin-right: 10px;
`

const Icon = styled.img`
  /* border: 1px red dashed; */
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
`

const HeaderText = styled.h5`
  /* border: 1px green dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-self: center;
  /* align-items: center; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const Text = styled.h5`
  /* border: 1px green dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-self: center;
  /* align-items: center; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`


const StaticText = styled(Text)`
  font-weight: 400;
  padding-left: 5px;
  /* border: 1px green dashed; */
  color: ${COLORS.HighlightLight};
  align-self: center;
`

const AlertIcon = styled.img`
  /* border: 1px red dashed;; */
  width: 24px; 
  height: 24px;
  `

const UserIcon = styled.img`
/* border: 1px red dashed;; */
width: 16px; 
height: 16px;
margin-left: 2px;
`

const AdminIcon = styled.img`
/* border: 1px red dashed;; */
/* width: 24px;  */
height: 24px;
`

const EditUser = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  /* position: relative; */
`






// render different table column combinations based on screen size

// React-table
// https://retool.com/blog/building-a-react-table-component/
// https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/basic?file=/src/App.js
// https://codesandbox.io/s/cocky-poincare-b24mt?file=/src/App.js:123-157
// https://stackoverflow.com/questions/65161059/how-to-load-images-in-react-table-from-json-file

// Header search
// https://codesandbox.io/s/react-table-select-uh3i0?file=/src/HeaderButton.js:66-219

// Fuzzy search
// https://codesandbox.io/s/dq9ou?file=/src/index.js
// https://medium.com/analytics-vidhya/how-to-create-a-fuzzy-search-in-react-js-using-fuse-js-859f80345657
// https://fusejs.io/demo.html
// https://www.youtube.com/watch?v=GZl-yEz4_qw&t=214s&ab_channel=ColbyFayock

// {
//   Header: 'Serial',
//   accessor: 'serial',

// Cell: props => <PlaceholderImage width={60} textColor="#fff" text="Image" />
// Cell: ({ cell: { value } }) => (
//   <img
//     src={value}
//     width={60}
//   />
// )
// },
