import styled from "styled-components";
import { COLORS } from "./colors";

// Desktop & Tablet

export const Title = styled.h1`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
`;

export const H1 = styled.h1`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 600;
    font-size: 31px;
    line-height: 90%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
`;

export const H2 = styled.h2`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 110%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
`;

export const H3 = styled.h3`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 110%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
`;

export const Subheader = styled.h4`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
`;

export const BodyLarge = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #fff;
`;

export const BodySmall = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
`;

export const FieldText = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 129.5%;
    color: ${COLORS.White};
`;

export const ButtonText = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 129.5%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
`;

export const Label = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 129.5%;
    letter-spacing: 0.03em;
    /* text-transform: capitalize; */
    color: ${COLORS.HighlightLight};
`;

export const TableText = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.01em;
`;

export const LinkText = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 129.5%;
`;




// Mobile

export const TitleMobile = styled.h1`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
`;

export const H1Mobile = styled.h1`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
`;

export const H2Mobile = styled.h2`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 110%;
    letter-spacing: -0.01em;
`;

export const H3Mobile = styled.h3`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.01em;
`;

export const SubheaderMobile = styled.h4`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
`;

export const BodyLargeMobile = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
`;

export const BodySmallMobile = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
`;

export const FieldTextMobile = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 129.5%;
`;

export const ButtonTextMobile = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 129.5%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
`;

export const LabelMobile = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 129.5%;
    letter-spacing: 0.03em;
    text-transform: capitalize;
`;

export const TableTextMobile = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.01em;
`;

export const LinkTextMobile = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 129.5%;
`;