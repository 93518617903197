import React, { Component, useMemo } from "react"
import { API, Auth } from "aws-amplify"
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import media from "styled-media-query"
import styled from "styled-components"
import Fuse from "fuse.js"
import UsersController from "./UsersController"
import UsersTableStructure from "./UsersTableStructure"
import WingnutStatus from "../wingnuts/WingnutStatus.enum"
import { BodyLarge, H2, Label } from '../../styles/fonts'
import { COLORS } from "../../styles/colors"
import { AccountContext } from "../../auth/Account"
import Modal from "react-modal"
import InviteUserRouter from "./new_user_modal/InviteUserRouter"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}

const fuzzyOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 3,
  // location: 0,
  threshold: 0.1,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["firstName", "lastName", "phone", "email", "type"],
}

export default class UsersOverview extends Component {

  static contextType = AccountContext;

  constructor() {
    super()
    this.fuseSearch = null
    this.showData = true

    this.state = {
        data: [],
        notificationData: [],
        search: "",
        isLoading: true,
        isInviteOpen: false,
        isAdmin: false,
        loggedInEmail: ''
      }
  }

  componentDidMount() {

    const { getSession } = this.context;
    getSession().then(({ isAdmin, email }) => {
      if(isAdmin){
        this.getUsers()
        this.setState({ isAdmin: true, loggedInEmail: email})
      } else {
        this.setState({ isAdmin: false, loggedInEmail: email, data: []})
      }
    });
  }

  componentWillUnmount() {
    this.showData = false
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.isLoading === true && this.state.isLoading === false){
      this.filterData(this.state.search, this.state.status)
    } 
  }

  searchForUser(event) {
    if(this.state.isLoading === false){
      this.filterData(event.target.value)
    } else {
      this.setState({
        search: event.target.value,
      })
    }
  }

  filterData(fuzzySearch) {
    var data = []
    // If using the fuzzy search "bre.."
    if (fuzzySearch) {
      data = this.fuseSearch.search(fuzzySearch).map((s) => s.item)
    } else {
      // If using the eye filter icons
      if(this.fuseSearch){
        data = this.fuseSearch.getIndex().docs
      }
    }

    this.setState({
      search: fuzzySearch,
      data: data,
      didMerge: false
    })
  }

  refreshTable = () => {
    this.getUsers(); // Call it directly
};

  async getUsers() {
    console.log("hello1", this.state.loggedInEmail)
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }

    API.get("FrontendAPI-companies", "/users", init)
      .then((response) => {
        if (response.success) {

          var mappedData = response.data.map((item) => {
              return {
                firstName: item.config.firstName,
                lastName: item.config.lastName,
                phone: item.config.phone,
                email: item.config.email,
                type: item.config.role,
                uuid: item.id,
                status: item.entityStatus
              }
            
          })

          // If logged in user is not a wingnut employee, they are a client and remove any wingnut emails
          if(!this.state.loggedInEmail.endsWith('@wingnutiot.ca')){
            mappedData = mappedData.filter((item) => !item.email.endsWith('@wingnutiot.ca')); // The domain to exclude
          } 

          if (this.showData) {
            this.setupTable(mappedData)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  setupTable(data) {
    this.fuseSearch = new Fuse(data, fuzzyOptions)
    this.setState({
      data: data,
    })
  }

  closeInvitePopup = () => {
    this.setState({
      isInviteOpen: false,
    })
  }

  openInvitePopup = () => {
    this.setState({
      isInviteOpen: true,
    })
  }

  
  render() {
    
    return (
      <OuterContainer>
          <Modal isOpen={this.state.isInviteOpen} onRequestClose={this.closeInvitePopup} style={customStyles}>
            <InviteUserRouter  seatsUsed={this.state.data?.length} refreshTable={this.refreshTable} closePopup={this.closeInvitePopup}/>
          </Modal>

      {this.state.isAdmin === true ?
          <div>
            <HeaderContainer>
              <H2 style={{color: '#FFF'}}>Company Users</H2>
              <Button onClick={this.openInvitePopup}>INVITE NEW USER</Button>
            </HeaderContainer>
            <Line />
            <UsersController
              tableData={this.state.data}
              toggleStatus={(s) => this.toggleStatus(s)}
              onInputChange={(e) => this.searchForUser(e)}
            />
            { this.state.isLoading
            ? (<SpinnerContainer><StyledSpinner animation='border' /></SpinnerContainer>)
            : (<UsersTableStructure refreshTable={this.refreshTable} tableData={this.state.data} />)}
        </div >
      :
      <div>
        <HeaderContainer>
          <H2 style={{color: '#FFF'}}>Missing user permissions</H2>
        </HeaderContainer>
        <Line />
      </div>

    }


      </OuterContainer>
    )
  }
}


const OuterContainer = styled.div`
  /* border: 1px yellow dashed; */
  background-color: #0E1C36;
  border-radius: 20px;
  height: 100%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  padding-left: 33px;
  padding-right: 33px;
  padding-top: 20px;
  margin-right: 30px;
  ${media.lessThan("743px")`
    justify-content: center;
    /* padding-top: 10px; */
      margin-left: 30px;
      align-content: center;
      width: auto;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${media.lessThan("743px")`
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  `}
  ${media.between("744px", "large")`
    `}
  ${media.greaterThan("large")`
  `}
`;

const Line = styled.div`
  /* width: 80%; */
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 15.5px;
  margin-bottom: 19px;
  /* margin-left: 20px; */
`


const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: ${COLORS.Green};
  color: ${COLORS.White};
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.05em;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  color: black;
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        align-self: center;
        height: 55px;
        margin-right: 0px;
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

// mergeData(){
//   const properties = this.state.data
//   const notifications = this.state.notificationData
//   const toggleProperties = []

//   properties.forEach((property) => {
//     var item = {}
//     if (item = notifications.find(notification => notification.propertyId === property.uuid)) {
//       const newProperty = {
//         ...property,
//         toggleStatus: item.entityStatus,
//         notificationId: item.notificationId
//       }
//       toggleProperties.push(newProperty)
//    }
//   })

//   this.setState({
//     data: toggleProperties,
//     didMerge: true
//   })
// }

// async getProperties() {
//   const token = (await Auth.currentSession()).getIdToken()
//   const jwt = token.getJwtToken()
//   const init = {
//     headers: { Authorization: `Bearer ${jwt}` },
//   }

//   API.get("FrontendAPI-properties", "", init)
//     .then((response) => {
//       if (response.success) {
//         const mappedData = response.data.map((item) => {

//           var alarmState = "OK"

//           if(item.config && item.config.alarms !== null && item.config.alarms !== undefined){

//             const alarms = item.config.alarms;

//             // Check if any of the alarms are set to "ALARM"
//             const isAnyAlarmSetToAlarm = Object.values(alarms).some(value => value === "ALARM");
            
//             if (isAnyAlarmSetToAlarm) {
//               alarmState = "ALARM"
//             } else {
//               alarmState = "OK"
//             }

//         }

//           return {
//             propertyId: parseInt(item.externalId),
//             propertyIdDisplay: item.externalId,
//             propertyName: item.config.name,
//             uuid: item.id,
//             alarmState: alarmState,
//             address: item.config.address,
//             portfolio: item.config.portfolio,
//             entityStatus: item.entityStatus === "enabled" ? 1 : 0,  
//             linkedWingnuts: item.linkedWingnuts,
//           }
//         })
//         if (this.showData) {
//           this.setupTable(mappedData)
//         }
//       }
//     })
//     .catch((error) => {
//       console.error(error)
//     })
//     .finally(() => this.setState({ isLoading: false }))
// }

// async getNotificationToggles() {
//   const token = (await Auth.currentSession()).getIdToken()
//   const jwt = token.getJwtToken()
//   const init = {
//     headers: { Authorization: `Bearer ${jwt}` },
//   }

//   API.get("FrontendAPI-notifications", "", init)
//     .then((response) => {
//       if (response.success) {
//         const mappedNotifications = response.data.map((item) => {
//           return {
//             propertyId: item.config.propertyId,
//             notificationId: item.id,
//             entityStatus: this.translateToggle(item.entityStatus),
//           }
//         })
//         if (this.showData) {
//           this.setState({
//             notificationData: mappedNotifications,
//           })
//         }
//       }
//     })
//     .catch((error) => {
//       console.error("THERE WAS ERROR", error)
//     })
//     .finally(() => this.setState({ areTogglesLoading: false }))
// }

// translateToggle(status) {
//   if(status === "enabled"){
//     return 1
//   } else {
//     return 0
//   }
// }