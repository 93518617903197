import React, { Link, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { useForm } from "react-hook-form"
import Image3 from "./../../../images/ic_arrow_right_green.svg"
import ImgClose from "./../../../images/ic_close.svg"
import ImgCheck from "./../../../images/ic_check_circle.svg"
import ImgError from "./../../../images/ic_error.svg"
import ImgAccount from "./../../../images/ic_account_white.svg"
import ImgVerified from "./../../../images/ic_verified.svg"
import ImgViewer from "./../../../images/ic_viewer.svg"
import ImgProperty from "./../../../images/ic_property.svg"
import { COLORS } from "../../../styles/colors"
import { BodySmall, FieldText, H1, H2, H3 } from "../../../styles/fonts"
import { API, Auth } from "aws-amplify"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 705px;
  height: 466px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.Primary};
  border-radius: 20px;
  ${media.lessThan("743px")`
    /* justify-content: center; */
    width: auto;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    `}
  ${media.between("744px", "large")`
    padding-bottom: 30px;
    width: 650px;
  `}
  ${media.greaterThan("large")`
    padding-bottom: 30px;
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  ${media.lessThan("743px")`
    /* padding: 10px; */
    /* width: 350px; */
        /* width: auto; */
    `}
  ${media.between("744px", "large")`
        /* padding-right: 17px; */
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnClose = styled.button`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
  background: transparent;
  border: none;
  outline: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`

const WingnutIcon = styled.img`
  /* width: 16px; */
  /* height: 16px; */
  /* margin-bottom: 17px; */
  align-self: center;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  align-self: center;
`

const Checkbox = styled.input`
  border: 1px solid ${COLORS.White};
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: transparent;
`

const ButtonContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
  ${media.lessThan("743px")`
    flex-direction: column-reverse;
    gap: 5px;
    align-self: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 237px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: #63c085;
  color: black;
  cursor: pointer;
  margin-bottom: 10px;
  ${media.lessThan("743px")`
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnNo = styled(Button)`
  background: transparent;
  color: ${COLORS.White};
  margin-left: 5px;
  ${media.lessThan("743px")`
        align-self: center;
    `}
`

const ButtonTextNo = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  transition-duration: 0.5s;
  :hover {
  }
`

const ButtonTextYes = styled(ButtonTextNo)`
  color: ${COLORS.Base};
`

const StackColumn = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;      
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-self: center;
    gap: 0;
  `;

const StackRow = styled.div`
    /* border: 1px red dashed; */
    display: flex;      
    flex-direction: row;
    justify-content: start;
    align-items: center;
`;

const Stack = styled.div`
    /* border: 1px red dashed; */
    display: flex;      
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 45px;
    ${media.lessThan("743px")`
        flex-direction: column;
        gap: 16px;
        align-items: start;
    `}
    ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;


const Form = styled.form`
  /* border: 1px blue dashed; */
  /* width: 500px; */
  display: flex;
  flex-direction: column;
  margin-top: 63px;
  /* margin-right: 83px; */
  ${media.lessThan("743px")`
        align-self: center;
        width: 100%;
        margin-right: 0px;
        margin-top: 30px;
    `}
  ${media.between("744px", "large")`
        /* width: 384px; */
    `}
`


const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 384px;
  /* max-width: 378px; */
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: 400;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  align-self: center;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        /* width: 100%; */
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const StyledBodySmall = styled(BodySmall)`
  ${media.lessThan("743px")`
    /* width: 330px; */
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

export default function ChangePlan(props) {

  const [isHovering, setIsHovering] = useState(false)
  const [isHoveringYes, setIsHoveringYes] = useState(false)

  return (
      <Container>

        {/* -----------------------------Change Plan */}
        <WingnutDetails style={{height: '100%'}}>
            <img src={ImgError} style={{width: '20px', alignSelf: 'center'}} />
            <H2 style={{alignSelf: 'center', textAlign: 'center', color: COLORS.White, marginTop: '12px'}}>
                You don’t have any more seats available on your plan
            </H2>
            <H3 style={{alignSelf: 'center', textAlign: 'center', marginTop: '6px'}}>Please upgrade your plan to add more users</H3>
            
            <ButtonContainer>
            <Button
                onMouseEnter={(e) => {
                setIsHovering(true)
                }}
                onMouseLeave={(e) => {
                setIsHovering(false)
                }}
                onClick={props.closePopup}
                style={{ backgroundColor: isHovering == true ? COLORS.Secondary : "transparent", width: '205px' }}
            >
                <ButtonTextNo>CLOSE</ButtonTextNo>
            </Button>

            {/* <Button
                onMouseEnter={(e) => {
                setIsHoveringYes(true)
                }}
                onMouseLeave={(e) => {
                setIsHoveringYes(false)
                }}
                style={{ opacity: isHoveringYes == true ? "80%" : "100%", width: '205px' }}
            >
                <ButtonTextYes>CHANGE MY PLAN</ButtonTextYes>
            </Button> */}

            </ButtonContainer>
        </WingnutDetails>

    </Container>

  )
}
