import React, { useState, useContext, useEffect } from "react"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { AccountContext } from "./../../auth/Account"
import { Link, useLocation } from "react-router-dom"
import MediaQuery from "react-responsive"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/LOGO_ALT.svg"
import HelpImage from "./../../images/ic_help.svg"
import Image2 from "./../../images/ic_dashboard.svg"
import Image2_Selected from "./../../images/ic_dashboard_active.png"
import Image2_Hover from "./../../images/ic_dashboard_white.svg"
import Image3 from "./../../images/ic_account.svg"
import Image3_Selected from "./../../images/ic_account_active.svg"
import Image3_Hover from "./../../images/ic_account_white.svg"
import Image4 from "./../../images/ic_logout.svg"
import Image4_Selected from "./../../images/ic_logout_active.svg"
import Image4_Hover from "./../../images/ic_logout_white.svg"
import Image5 from "./../../images/ic_wingnuts.svg"
import Image5_Selected from "./../../images/ic_wingnuts_active.svg"
import Image5_Hover from "./../../images/ic_wingnuts_white.svg"
import Image6 from "./../../images/ic_property.svg"
import Image6_Selected from "./../../images/ic_property_active.svg"
import Image6_Hover from "./../../images/ic_property_white.svg"
import Image7 from "./../../images/ic_notification.svg"
import Image7_Selected from "./../../images/ic_notification_active.svg"
import Image7_Hover from "./../../images/ic_notification_white.svg"
import Modal from "react-modal"
import LogoutPopup from "./../popup/LogoutPopup"
import { COLORS } from "../../styles/colors"
import { getUUIDFromPath } from "../util/CleanUUID"

const Container = styled.div`
  background: ${COLORS.Primary};
  padding-top: 26px;
  padding-left: 29px;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`

const Logo = styled.img`
  width: 94px;
  height: 27px;
  align-self: center;
  /* transition-duration: 0.5s;
    :hover{
        transform: scale(1.2);
    } */
`

const MenuItemContainer = styled.div`
  /* border: 1px red dashed; */
  height: 40px;
  display: flex;
  flex-direction: row;
  align-content: center;
`

const MenuItemContainer2 = styled(MenuItemContainer)`
  margin-top: 46px;
`

const Icon = styled.img`
  /* border: 1px red dashed; */
  width: 16px;
  height: 16px;
  margin-right: 16px;
  align-self: center;
  color: ${COLORS.White};
`

const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const HelpIcon = styled.img`
  width: 25px;
  height: 25px;
  align-self: right;
  margin-right: 10px;
`

const MenuText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 129.5%;
  align-self: center;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  /* text-decoration: none; */
`

const Line = styled.div`
  width: 30%;
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 14px;
  margin-right: 30px;
`

const Conditions = styled.p`
  margin-top: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 130%;
  text-decoration-line: underline;
  color: ${COLORS.White};
  /* align-self: center; */
`

const Copyright = styled.p`
  /* text-align: center; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 130%;
  color: ${COLORS.HighlightMedium};
  margin-top: 7px;
  margin-bottom: 7px;
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}

export default function NavBar() {
  const [expanded, setExpanded] = useState(false)

  const [hoverDashboard, setHoverDashboard] = useState(false)
  const [hoverAccount, setHoverAccount] = useState(false)
  const [hoverWingnut, setHoverWingnut] = useState(false)
  const [hoverProperty, setHoverProperty] = useState(false)
  const [hoverNotification, setHoverNotification] = useState(false)
  const [hoverUsers, setHoverUsers] = useState(false)
  const [hoverLogout, setHoverLogout] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { logout } = useContext(AccountContext)
  var location = useLocation()

  const [isAdmin, setIsAdmin ] = useState(false)
  const { getSession } = useContext(AccountContext)
  
  useEffect(() => {
    getSession().then(({ isAdmin }) => {
      setIsAdmin(isAdmin)
    })
  }, [])
  
  const [state, setState] = useState({
    selectDashboard: true,
    selectAccount: false,
    selectWingnut: false,
    selectProperty: false,
    selectNotification: false,
    selectUsers: false,
    selectLogout: false,
  })

  const closePopup = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    var pathname = location.pathname.substring(8)

    switch (pathname) {
      case "dashboard/0":
        return setState({
          selectDashboard: true,
          selectAccount: false,
          selectLogin: false,
          selectWingnut: false,
          selectProperty: false,
          selectNotification: false,
          selectUsers: false,
        })
      case "account":
        return setState({
          selectDashboard: false,
          selectAccount: true,
          selectLogin: false,
          selectWingnut: false,
          selectProperty: false,
          selectNotification: false,
          selectUsers: false,
        })
      case "wingnut":
        return setState({
          selectDashboard: false,
          selectAccount: false,
          selectLogin: false,
          selectWingnut: true,
          selectProperty: false,
          selectNotification: false,
          selectUsers: false,
        })
        case `wingnut/detail/${getUUIDFromPath(location.pathname)}`:
          return setState({
            selectDashboard: false,
            selectAccount: false,
            selectLogin: false,
            selectWingnut: true,
            selectProperty: false,
            selectNotification: false,
            selectUsers: false,
          })
        case "notification":
          return setState({
            selectDashboard: false,
            selectAccount: false,
            selectLogin: false,
            selectWingnut: false,
            selectProperty: false,
            selectNotification: true,
            selectUsers: false,
          })
        case "property":
          return setState({
            selectDashboard: false,
            selectAccount: false,
            selectLogin: false,
            selectWingnut: false,
            selectProperty: true,
            selectNotification: false,
            selectUsers: false,
          })
          case "users":
            return setState({
              selectDashboard: false,
              selectAccount: false,
              selectLogin: false,
              selectWingnut: false,
              selectProperty: false,
              selectNotification: false,
              selectUsers: true,
            })
        case `property/detail/${getUUIDFromPath(location.pathname)}`:
          return setState({
            selectDashboard: false,
            selectAccount: false,
            selectLogin: false,
            selectWingnut: false,
            selectProperty: true,
            selectNotification: false,
            selectUsers: false,
            })
    }
  }, [location])

  return (
    <div>
      <Modal isOpen={isModalOpen} onRequestClose={closePopup} style={customStyles} contentLabel='Wingnut Login'>
        <LogoutPopup closePopup={closePopup} setLogout={logout}/>
      </Modal>

      <Container id='navbar'>
        <Navbar bg='#000' variant='dark' expand='sl' expanded={expanded}>
          <Navbar.Brand>
            <Logo src={Image} width='50px' height='40px' />
            {""}
          </Navbar.Brand>

          <HelpContainer>
            <MediaQuery maxWidth={744}>
              <Link to='/portal/help' style={{ textDecoration: "none", alignSelf: "center" }}>
                <HelpIcon src={HelpImage} />
              </Link>
            </MediaQuery>

            <Navbar.Toggle
              onClick={() => setExpanded(expanded ? false : "expanded")}
              aria-controls='responsive-navbar-nav'
              style={{ marginRight: 30, border: "none", boxShadow: "none" }}
            />
          </HelpContainer>

          <Navbar.Collapse>
            <Nav>
              <Link
                to='/portal/dashboard/0'
                style={{ textDecoration: "none", color: COLORS.HighlightMedium }}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false)
                  }, 150)
                }
                onMouseEnter={(e) => {
                  setHoverDashboard(true)
                }}
                onMouseLeave={(e) => {
                  setHoverDashboard(false)
                }}
              >
                <MenuItemContainer2
                  onClick={() => {
                    setState({
                      selectDashboard: true,
                      selectAccount: false,
                      selectLogin: false,
                      selectWingnut: false,
                      selectProperty: false,
                      selectNotification: false,
                      selectUsers: false,
                    })
                  }}
                >
                  <Icon src={state.selectDashboard ? Image2_Selected : hoverDashboard ? Image2_Hover : Image2} />
                  <MenuText style={{ color: state.selectDashboard ? COLORS.White : hoverDashboard ? COLORS.White : null }}>
                    DASHBOARD
                  </MenuText>
                </MenuItemContainer2>
              </Link>

              <Link
                to='/portal/account'
                style={{ textDecoration: "none", color: COLORS.HighlightMedium }}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false)
                  }, 150)
                }
                onMouseEnter={(e) => {
                  setHoverAccount(true)
                }}
                onMouseLeave={(e) => {
                  setHoverAccount(false)
                }}
              >
                <MenuItemContainer
                  onClick={() => {
                    setState({
                      selectDashboard: false,
                      selectAccount: true,
                      selectLogin: false,
                      selectWingnut: false,
                      selectProperty: false,
                      selectNotification: false,
                      selectUsers: false,
                    })
                  }}
                >
                  <Icon src={state.selectAccount ? Image3_Selected : hoverAccount ? Image3_Hover : Image3} />
                  <MenuText style={{ color: state.selectAccount ? COLORS.White : hoverAccount ? COLORS.White : null }}>
                    ACCOUNT
                  </MenuText>
                </MenuItemContainer>
              </Link>

              <Link
                to='/portal/wingnut'
                style={{ textDecoration: "none", color: COLORS.HighlightMedium }}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false)
                  }, 150)
                }
                onMouseEnter={(e) => {
                  setHoverWingnut(true)
                }}
                onMouseLeave={(e) => {
                  setHoverWingnut(false)
                }}
              >
                <MenuItemContainer
                  onClick={() => {
                    setState({
                      selectDashboard: false,
                      selectAccount: false,
                      selectLogin: false,
                      selectWingnut: true,
                      selectProperty: false,
                      selectNotification: false,
                      selectUsers: false,
                    })
                  }}
                >
                  <Icon
                  // style={{height: "20px", width: "20px", marginRight: "14px", marginLeft: "-2px"}}
                    style={{ height: 22, width: 20, marginRight: "14px", marginLeft: "-2px" }}
                    src={state.selectWingnut ? Image5_Selected : hoverWingnut ? Image5_Hover : Image5}
                  />
                  <MenuText style={{ color: state.selectWingnut ? COLORS.White : hoverWingnut ? COLORS.White : null }}>
                    WINGNUTS
                  </MenuText>
                </MenuItemContainer>
              </Link>

              <Link
                to='/portal/property'
                style={{ textDecoration: "none", color: COLORS.HighlightMedium }}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false)
                  }, 150)
                }
                onMouseEnter={(e) => {
                  setHoverProperty(true)
                }}
                onMouseLeave={(e) => {
                  setHoverProperty(false)
                }}
              >
                <MenuItemContainer
                  onClick={() => {
                    setState({
                      selectDashboard: false,
                      selectProperty: true,
                      selectLogin: false,
                      selectWingnut: false,
                      selectProperty: true,
                      selectNotification: false,
                      selectUsers: false,
                    })
                  }}
                >
                  <Icon src={state.selectProperty ? Image6_Selected : hoverProperty ? Image6_Hover : Image6} />
                  <MenuText style={{ color: state.selectProperty ? COLORS.White : hoverProperty ? COLORS.White : null }}>
                    PROPERTY
                  </MenuText>
                </MenuItemContainer>
              </Link>

              <Link
                to='/portal/users'
                style={{ textDecoration: "none", color: COLORS.HighlightMedium }}
                onClick={() =>
                  setTimeout(() => {
                    setExpanded(false)
                  }, 150)
                }
                onMouseEnter={(e) => {
                  setHoverUsers(true)
                }}
                onMouseLeave={(e) => {
                  setHoverUsers(false)
                }}
              >
                <MenuItemContainer
                  style={{display: isAdmin ? 'block' : 'none'}}
                  onClick={() => {
                    setState({
                      selectDashboard: false,
                      selectProperty: true,
                      selectLogin: false,
                      selectWingnut: false,
                      selectProperty: false,
                      selectNotification: false,
                      selectUsers: true,
                    })
                  }}
                >
                  <Icon src={state.selectUsers ? Image6_Selected : hoverUsers ? Image6_Hover : Image6} />
                  <MenuText style={{ color: state.selectUsers ? COLORS.White : hoverUsers ? COLORS.White : null }}>
                    USERS
                  </MenuText>
                </MenuItemContainer>
              </Link>

                <MenuItemContainer
                    onMouseEnter={(e) => {
                      setHoverLogout(true)
                    }}
                    onMouseLeave={(e) => {
                      setHoverLogout(false)
                    }}
                    style={{  cursor: "pointer" }}
                  onClick={() => {
                    setIsModalOpen(true)
                    setState({
                      selectDashboard: false,
                      selectAccount: false,
                      selectLogin: true,
                      selectWingnut: false,
                      selectProperty: false,
                    })
                  }}
                >
                  <Icon src={state.selectLogout ? Image4_Selected : hoverLogout ? Image4_Hover : Image4} />
                  <MenuText
                    style={{ color: state.selectLogout ? COLORS.White : hoverLogout ? COLORS.White :  COLORS.HighlightMedium }}
                  >
                    LOG OUT
                  </MenuText>
                </MenuItemContainer>


              <LinkContainer>
                <Link to='/terms' style={{ textDecoration: "none", alignSelf: "center" }}>
                  <Conditions>Terms & Conditions</Conditions>
                </Link>

                <Conditions style={{ textDecoration: "none", marginLeft: "3px", marginRight: "3px" }}> & </Conditions>

                <Link to='/privacy' style={{ textDecoration: "none", alignSelf: "center" }}>
                  <Conditions>Privacy Policy</Conditions>
                </Link>
              </LinkContainer>
              <Copyright>Wingnut IoT Inc.</Copyright>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  )
}
