import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgClose from "./../../../images/ic_close.svg"
import { COLORS } from "../../../styles/colors"
import ChangeUser from './ChangeUser'
import ChangeUserSuccess from './ChangeUserSuccess'
import DeleteUser from './DeleteUser'
import { API, Auth } from "aws-amplify"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 705px;
  height: 500px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.Primary};
  border-radius: 20px;
  ${media.lessThan("743px")`
    /* justify-content: center; */
    width: auto;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    `}
  ${media.between("744px", "large")`
    padding-bottom: 30px;
    width: 650px;
  `}
  ${media.greaterThan("large")`
    padding-bottom: 30px;
    `}
`

const BtnClose = styled.button`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
  background: transparent;
  border: none;
  outline: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`

export default function ChangeUserRouter(props) {

  const [inviteFlow, setInviteFlow] = useState(props.popup)
  const [seats, setSeats] = useState(0)

    useEffect(() => {
        getCompanySeats()
    }, [])

    const getCompanySeats = async (data) => {
      console.log("onSubmit")
  
        const token = (await Auth.currentSession()).getIdToken()
        const jwt = token.getJwtToken()
        const init = {
          headers: { Authorization: `Bearer ${jwt}` }
        }
  
        API.get('FrontendAPI-companies', '/details', init)
          .then((response) => {
            console.log("response", response)
            if (response.success) {
                console.log("Response", response.data)
                if(response.data && response.data.config && response.data.config.seats){
                    setSeats(response.data.config.seats)
                }
            }
          })
        .catch((err) => {
          console.error(err)
        })
      }

  const toChangeSuccess = () => {
    setInviteFlow(1)
  }

  const toDeleteUser = () => {
    setInviteFlow(2)
  }

  const backToChange = () => {
    setInviteFlow(0)
  }


  return (
      <Container>
      <BtnClose onClick={props.closePopup}>
        <CloseIcon src={ImgClose} />
      </BtnClose>

      {/* -----------------------------Change User & Enter Email */}

      {(() => {
            switch (inviteFlow) {
                case 0:
                    return <ChangeUser {...props} backToChange={backToChange} toChangeSuccess={toChangeSuccess} toDeleteUser={toDeleteUser} refreshTable={props.refreshTable}/>
                case 1:
                    return <ChangeUserSuccess backToChange={backToChange} closePopup={props.closePopup}/>;
                case 2:
                    return <DeleteUser {...props} closePopup={props.closePopup} backToChange={backToChange} refreshTable={props.refreshTable}/>
                default:
                    return null
              }
        })()}
      
    </Container>

  )
}
